/* frontend/src/styles/global.css */

/* Reset geral */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: auto;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #f1f1f1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden; /* Evita barras horizontais */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Content Wrapper */
.content-wrapper {
  position: relative;
  z-index: 1;
  padding-top: 80px; /* Espaço para o cabeçalho fixo */
}

/* Header (baseado em header.css) */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(18, 18, 18, 0.9);
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.hamburger {
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.navbar {
  display: flex;
}

.navbar ul {
  display: flex;
  list-style: none;
  gap: 20px;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navbar ul li a:hover {
  color: #ff6b81;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.social-icon {
  color: white;
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #ff6b81;
}

.brand-link {
  color: #ff6b81;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.login-btn {
  padding: 8px 20px;
  font-size: 1rem;
  color: #ffffff;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  transition: color 0.3s ease, transform 0.1s ease;
}

.login-btn::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #ff6b81;
  transition: width 0.3s ease, left 0.3s ease;
}

.login-btn:hover {
  color: #ff6b81;
}

.login-btn:hover::after {
  width: 100%;
  left: 0;
}

.login-btn:active {
  transform: scale(0.95);
}

/* Hero Section (baseado em hero.css) */
.hero {
  position: relative;
  color: white;
  text-align: center;
  padding: 50px 20px;
  height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;
}

#bg-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.hero #dynamic-text {
  font-size: 5rem;
  color: white;
  font-weight: bold;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  line-height: 80px;
  opacity: 1;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.hero #dynamic-text::after {
  content: "|";
  font-size: 5rem;
  color: white;
  margin-left: 5px;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.hero #dynamic-text.hidden {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hero .static-text {
  font-family: 'Roboto', sans-serif; /* Garante que a fonte seja Roboto */
  font-size: 1.2rem;
  font-weight: 400; /* Ajustado para um peso mais leve, como no design anterior */
  color: rgba(255, 255, 255, 0.7);
  z-index: 2;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 25%; /* Mantém a posição ajustada */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  animation-delay: 0.3s; /* Delay de 300ms */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Download Section (baseado em download-section.css) */
.download-section {
  background-color: transparent;
  padding: 20px 0;
  text-align: center;
  position: relative;
  z-index: 1;
}

.download-buttons {
  position: fixed;
  bottom: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in forwards;
  animation-delay: 0.5s;
  width: fit-content;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  animation-fill-mode: forwards;
}

.download-buttons.hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateY(20px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.download-buttons a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  text-decoration: none;
  background-color: transparent;
  border-radius: 5px;
  overflow: hidden;
}

.download-buttons a img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.download-buttons a:hover img {
  opacity: 0.8;
}

/* Testimonials Section */
.testimonials {
  background-color: #1a1a1a;
  padding: 50px 20px;
  text-align: center;
  position: relative;
  z-index: 3;
}

.testimonials h2 {
  font-size: 2.5rem;
  color: #ff6b81;
  margin-bottom: 40px;
  font-weight: bold;
}

.testimonial-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  background-color: #2a2a2a;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  color: #f1f1f1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 15px;
}

.card .author {
  font-size: 0.9rem;
  color: #ff6b81;
  font-weight: bold;
}

/* Footer */
.footer {
  background-color: #1a1a1a;
  padding: 30px 20px;
  text-align: center;
  color: #f1f1f1;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  padding-right: 0;
}

.footer-links div {
  margin-bottom: 20px;
  min-width: 200px;
}

.footer-links h3 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

.footer-links h3:hover {
  color: #ff6b81;
  cursor: pointer;
}

.footer-links div {
  text-align: left;
}

.footer-links a {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  margin-bottom: 10px;
}

.footer-links a:hover {
  color: #ff6b81;
}

.footer-copyright {
  font-size: 0.9rem;
  color: #bbb;
  margin-top: 40px;
}

/* Modal (baseado em modal.css) */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal.open {
  display: flex;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.modal-content h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.login-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  color: white;
  background-color: #ff6b81;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-option:hover {
  background-color: #e55a6f;
}

.login-option.google-login {
  background-color: #3267d6;
  position: relative;
  overflow: hidden;
}

.login-option.google-login .fab {
  font-size: 1.5rem;
  color: white;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.login-option.google-login:disabled {
  background-color: #a0c3ff;
  cursor: not-allowed;
}

.login-option.google-login:disabled:hover {
  background-color: #a0c3ff;
}

.login-option.facebook-login {
  background-color: #3b5998;
}

.login-option.facebook-login:hover {
  background-color: #2d4373;
}

.login-option.phone-login {
  background-color: #34c759;
}

.login-option.phone-login:hover {
  background-color: #28a745;
}

.login-option:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.login-option:disabled:hover {
  background-color: #ccc;
}

.status-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9rem;
  text-align: center;
  min-height: 20px;
}

/* Media Queries Gerais */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navbar {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(18, 18, 18, 0.95);
    padding: 20px 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .navbar.active {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }

  .navbar ul {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .navbar ul li a {
    font-size: 1.2rem;
  }

  .social-icons {
    gap: 10px;
  }

  .social-icon {
    font-size: 1.1rem;
  }

  .brand-link {
    font-size: 1.3rem;
  }

  .login-btn {
    padding: 6px 15px;
    font-size: 0.9rem;
  }

  .hero {
    padding: 30px 15px;
    padding-top: 60px;
  }

  .hero #dynamic-text {
    font-size: 3rem;
    height: 50px;
    line-height: 50px;
  }

  .hero #dynamic-text::after {
    font-size: 3rem;
    margin-left: 3px;
  }

  .hero .static-text {
    font-size: 1rem;
    top: 20%; /* Ajustado para telas menores */
  }

  .download-buttons {
    bottom: 5%;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 300px;
  }

  .download-buttons a {
    width: 100%;
    max-width: 150px;
    height: 45px;
  }

  .testimonials {
    padding: 30px 15px;
  }

  .testimonials h2 {
    font-size: 2rem;
  }

  .card {
    width: 100%;
    max-width: 300px;
  }

  .footer {
    padding: 20px 15px;
  }

  .footer-links {
    justify-content: center;
    gap: 30px;
    padding-right: 0;
  }

  .footer-links div {
    min-width: 100%;
    text-align: center;
  }

  .footer-links h3 {
    font-size: 1.3rem;
  }

  .footer-links a {
    font-size: 0.9rem;
  }

  .footer-copyright {
    font-size: 0.8rem;
  }

  .modal-content {
    width: 90%;
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 1.2rem;
  }

  .login-option {
    padding: 8px;
    font-size: 0.9rem;
  }

  .status-message {
    font-size: 0.8rem;
  }

  .download-buttons a {
    max-width: 130px;
    height: 40px;
  }
}

@media (max-width: 400px) {
  .download-buttons {
    gap: 10px;
    max-width: 250px;
  }

  .download-buttons a {
    max-width: 130px;
    height: 40px;
  }
}