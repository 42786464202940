header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(18, 18, 18, 0.9);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.hamburger {
    display: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

.navbar {
    display: flex;
}

.navbar ul {
    display: flex;
    list-style: none;
    gap: 20px;
}

.navbar ul li a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.navbar ul li a:hover {
    color: #ff6b81;
}

.social-icons {
    display: flex;
    align-items: center;
    gap: 15px;
}

.social-icon {
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #ff6b81;
}

.brand-link {
    color: #ff6b81;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.login-btn {
    padding: 8px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-weight: bold;
    position: relative; /* Necessário para o sublinhado animado */
    transition: color 0.3s ease, transform 0.1s ease; /* Transição para cor e escala */
}

.login-btn::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #ff6b81; /* Sublinhado na cor rosa do Tzelo */
    transition: width 0.3s ease, left 0.3s ease; /* Animação do sublinhado */
}

.login-btn:hover {
    color: #ff6b81; /* Muda a cor do texto para o rosa do Tzelo */
}

.login-btn:hover::after {
    width: 100%; /* Expande o sublinhado */
    left: 0; /* Alinha o sublinhado à esquerda */
}

.login-btn:active {
    transform: scale(0.95); /* Reduz levemente o tamanho ao clicar */
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .navbar {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: rgba(18, 18, 18, 0.95);
        padding: 20px 0;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .navbar.active {
        display: block;
        opacity: 1;
        transform: translateY(0);
    }

    .navbar ul {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .navbar ul li a {
        font-size: 1.2rem;
    }

    .social-icons {
        gap: 10px;
    }

    .social-icon {
        font-size: 1.1rem;
    }

    .brand-link {
        font-size: 1.3rem;
    }

    .login-btn {
        padding: 6px 15px;
        font-size: 0.9rem;
    }
}